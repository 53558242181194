import { Box, Typography, styled } from "@mui/material";
import React, { useEffect } from "react";
import { CalcTextField } from "./CalcTextField";
import { CalcItem } from "./СalcItem";
import coins from "src/assets/images/icons/coins-stack.svg";
import tag from "src/assets/images/icons/price-tag.svg";
import hand from "src/assets/images/icons/hand-gesture.svg";
import logo from "src/assets/images/logo.svg";

export const InviteCalc = () => {
  const [data, setData] = React.useState({
    platformFee: 0,
    retailerSurcharge: 0,
    totalSalSac: 0,
    salary: 55000,
    endOfHire: 1,
    monthlyTakeHomePay: 0,
    taxFreePrice: 0,
    totalSavings: 0,
    totalSavingsPercentage: 0,
    netFee: 0,
    voucherValue: 0,
  });
  const updateField = (fieldName: string, value: string) => {
    const numericValue = Number(value);

    if (!isNaN(numericValue) && numericValue <= 10000000) {
      setData((prevData: any) => ({
        ...prevData,
        [fieldName]: value,
      }));
    }
  };

  useEffect(() => {
    function calc_taxes(country: string, salary: any) {
      let tax = 0,
        threshold = 0;
      if (country === "England") {
        if (salary <= 12570) {
          tax = 0;
          threshold = 0;
        } else if (salary >= 12571 && salary <= 50270) {
          tax = 32.0;
          threshold = 12571;
        } else if (salary >= 50271 && salary <= 125140) {
          tax = 42.0;
          threshold = 50271;
        } else {
          tax = 47.0;
          threshold = 125141;
        }
      } else if (country === "Ireland") {
        if (salary <= 12570) {
          tax = 0;
          threshold = 0;
        } else if (salary >= 12571 && salary <= 50270) {
          tax = 32.0;
          threshold = 12571;
        } else if (salary >= 50271 && salary <= 125140) {
          tax = 42.0;
          threshold = 50271;
        } else {
          tax = 47.0;
          threshold = 125141;
        }
      } else if (country === "Wales") {
        if (salary <= 12570) {
          tax = 0;
          threshold = 0;
        } else if (salary >= 12571 && salary <= 50270) {
          tax = 32.0;
          threshold = 12571;
        } else if (salary >= 50271 && salary <= 125140) {
          tax = 42.0;
          threshold = 50271;
        } else {
          tax = 47.0;
          threshold = 125141;
        }
      } else {
        //Scotland
        if (salary <= 12570) {
          tax = 0;
          threshold = 0;
        } else if (salary >= 12571 && salary <= 14732) {
          tax = 31.0;
          threshold = 12571;
        } else if (salary >= 14733 && salary <= 25688) {
          tax = 32.0;
          threshold = 14668;
        } else if (salary >= 25689 && salary <= 43662) {
          tax = 33.0;
          threshold = 25297;
        } else if (salary >= 43663 && salary <= 50270) {
          tax = 44.0;
          threshold = 43663;
        } else if (salary >= 50271 && salary <= 125140) {
          tax = 44.0;
          threshold = 50271;
        } else {
          tax = 48.0;
          threshold = 125141;
        }
      }

      return { data: [(100 - tax) / 100, threshold], tax: tax };
    }
    function calculate_bike_salary_sacrifice_in_plp(
      voucher_amount: number,
      salary: number,
      sacrifice_repayment: number,
      country = "England"
    ) {
      const voucher_value = Number(voucher_amount);
      const salary_sacrifice = sacrifice_repayment;
      const value = calc_taxes(country, salary)?.data;
      const tax1 = value[0];
      const threshold = value[1];
      const value2 = calc_taxes(country, salary - voucher_value)?.data;

      const tax2 = value2[0];
      const differenceOverThreshold = threshold;

      const netcost =
        (salary - differenceOverThreshold) * tax1 +
        (voucher_value - (salary - differenceOverThreshold)) * tax2;
      const takehomepay = netcost / salary_sacrifice; // The monthly cost with the discount
      const savings = voucher_value - netcost;
      const savingsPercent = (savings / voucher_value) * 100;
      const context = {
        per_month: Math.round(Number(takehomepay)),
        total_savings: Number(savings).toFixed(2),
        saving_percentage: Number(savingsPercent).toFixed(2) + "%",
        tax_percent: calc_taxes(country, salary)?.tax,
        saving_percentage_number: Number(savingsPercent).toFixed(2),
      };

      return context;
    }
    const calculate = () => {
      setData((prevData: any) => {
        const voucherValue = prevData.voucherValue
          ? Number(prevData.voucherValue)
          : 0;
        const totalVal = voucherValue * 1.06;
        const gogeta_Fee = Number((voucherValue * 0.06).toFixed(2));
        const salary_sacrifice_amount: any = totalVal
          ? Number((voucherValue + gogeta_Fee).toFixed(2))
          : 0;
        const saving: any = calculate_bike_salary_sacrifice_in_plp(
          Number(voucherValue),
          Number(prevData.salary),
          12
        );
        let param: any = {};

        param = {
          ...param,
          regular_gross: Number((salary_sacrifice_amount / 12).toFixed(2)),
          regular_gross_12: (totalVal / (12 / 12) / 12).toFixed(2),
        };
        if (param.regular_gross) {
          param = {
            ...param,
            net_regular: Number(
              (
                param.regular_gross *
                (1 - saving?.saving_percentage_number / 100)
              ).toFixed(2)
            ),
            net_regular_12:
              param.regular_gross_12 *
              (1 - saving?.saving_percentage_number / 100),
          };
        }
        if (param.net_regular?.toString()?.length) {
          param = {
            ...param,
            total_savings: saving?.total_savings,
            C2W_price: Math.round(+param.net_regular * 12),
          };
        }
        if (param.total_savings) {
          param = {
            ...param,
            net_total_amount: param?.net_regular * 12,
            net_total_amount_old: totalVal - param?.total_savings,
            total_savings_percentage: saving?.saving_percentage,
            saving_C2W: voucherValue - param.C2W_price,
          };
        }
        if (param.saving_C2W) {
          param = {
            ...param,
            saving_C2W_percentage: (+param.saving_C2W / voucherValue) * 100,
          };
        }
        param = {
          ...param,
          net_fee_percentage: Number(
            ((gogeta_Fee * (100 - saving.tax_percent)) / voucherValue).toFixed(
              1
            )
          ),
        };

        return {
          ...prevData,

          platformFee: gogeta_Fee,
          totalSalSac: salary_sacrifice_amount,
          monthlyTakeHomePay: Number(param?.net_regular?.toFixed(2)),
          taxFreePrice: Math.round(param?.C2W_price),
          totalSavings: Number(param?.saving_C2W?.toFixed(2)),
          totalSavingsPercentage: Math.round(param.saving_C2W_percentage),
          netFee: Number(param.net_fee_percentage),
        };
      });
    };
    calculate();
  }, [data.voucherValue, data.salary, setData]);

  return (
    <Wrapper>
      <Top>
        See your savings
        <a href="https://gogeta.bike/">
          <img alt="logo" src={logo} />
        </a>
      </Top>
      <BottomWrapper>
        <Bottom>
          <Left>
            <InputsWrapper>
              <BlueWrapper>
                <Typography>
                  Your voucher is flexible and you can spend your balance over
                  12 months.
                  <br />
                  So you can plan for ALL your cycling needs, tax free
                </Typography>
                <CalcTextField
                  name={"voucherValue"}
                  updateField={updateField}
                  labelText={`Flexi Voucher amount:`}
                  data={data}
                  tooltipText="This is the total voucher amount you wish to apply for"
                />
              </BlueWrapper>
              <CalcTextField
                name={"platformFee"}
                updateField={updateField}
                labelText={`Platform fee: </br><span>(representing ${
                  data.netFee ? data.netFee : 0
                }% net cost)</span>`}
                data={data}
                tooltipText="This platform fee allows gogeta to maintain our marketplace, finding you the best deals, as well as running our support teams. It also ensures that retailers never add a surcharge to use our voucher (even on discounted bikes) as well ensuring we have industries lowest end of hire fees. All of which means you maximise your savings. The headline gross fee is 6%, but because the platform fee is applied to your salary sacrifice amount, the net of tax amount is much lower."
                readOnly={true}
                type="text"
              />
              <CalcTextField
                name={"totalSalSac"}
                updateField={updateField}
                labelText="Total salary sacrifice:"
                data={data}
                tooltipText="The total value of the bike, accessories and platform fee.You will sacrifice this amount over your gross (pre-tax) salary, giving you a substantial tax saving."
                readOnly={true}
                type="text"
              />
              <CalcTextField
                name={"salary"}
                updateField={updateField}
                labelText="Annual salary:"
                data={data}
                tooltipText="Your gross base salary, not including any bonus, before any tax is deducted."
              />
            </InputsWrapper>
          </Left>
          <Right>
            <SmallItems>
              <CalcItem
                title={"Monthly take home pay reduced by"}
                value={
                  "£" +
                  (data.monthlyTakeHomePay
                    ? data.monthlyTakeHomePay.toLocaleString("en-US")
                    : 0) +
                  "<br/><span>(for 12 months)</span>"
                }
                textColor="#0A244B"
                titleColor="#155C7E"
                shortTextColor="#0A244B"
                background="#E3F1F0"
                icon={coins}
              />
              <Box sx={{ "&  span": { textDecoration: "line-through" } }}>
                <CalcItem
                  title={"Tax free price"}
                  value={
                    "£" +
                    (data.taxFreePrice
                      ? data.taxFreePrice.toLocaleString("en-US")
                      : 0) +
                    `<br/><span>${
                      data.voucherValue > 0
                        ? "(£" + data.voucherValue.toLocaleString("en-US") + ")"
                        : ""
                    }</span>`
                  }
                  textColor="#0A244B"
                  titleColor="#155C7E"
                  shortTextColor="#0A244B"
                  background="#E3F1F0"
                  icon={tag}
                />
              </Box>
            </SmallItems>
            <CalcItem
              title={"Total savings"}
              value={
                "£" +
                (data.totalSavings
                  ? data.totalSavings.toLocaleString("en-US")
                  : 0) +
                ` <span>${
                  data?.totalSavings > 0
                    ? "(" + Math.round(data.totalSavingsPercentage) + "%)"
                    : "(0%)"
                }</span>`
              }
              textColor="#FFFFFF"
              titleColor="#87F0C9"
              shortTextColor="#87F0C9"
              background="#155C7E"
              icon={hand}
              isLarge
            />
          </Right>
        </Bottom>
        <GreenBlock>
          <Header>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <g clipPath="url(#clip0_1945_28004)">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M0.75 10.9422C0.75 5.3133 5.31095 0.75 10.9374 0.75C16.5639 0.75 21.1248 5.3133 21.1248 10.9422C21.1248 12.9276 20.5571 14.7811 19.5752 16.3482C19.7236 16.4718 19.8716 16.5946 20.0195 16.7171L20.0251 16.7218C20.8421 17.3995 21.6531 18.0722 22.4541 18.815C23.4157 19.7067 23.5483 21.0936 22.6139 22.0602C22.5282 22.1488 22.4369 22.2417 22.3396 22.3391C22.2424 22.4364 22.1495 22.5278 22.061 22.6135C21.095 23.5487 19.7087 23.4159 18.8176 22.4536C18.0753 21.6521 17.4031 20.8405 16.726 20.0229L16.7228 20.0191L16.7218 20.0179C16.5998 19.8706 16.4777 19.7232 16.3548 19.5753C14.7855 20.563 12.9278 21.1344 10.9374 21.1344C5.31095 21.1344 0.75 16.5711 0.75 10.9422ZM11 17.5C14.5899 17.5 17.5 14.5898 17.5 11C17.5 7.41015 14.5899 4.5 11 4.5C7.41015 4.5 4.5 7.41015 4.5 11C4.5 14.5898 7.41015 17.5 11 17.5Z"
                  fill="black"
                />
                <path
                  d="M11.0001 6.25C11.4143 6.25 11.7501 6.5858 11.7501 7V7.4467C12.2081 7.51305 12.5987 7.63135 12.903 7.74965C12.903 7.74965 13.0728 7.78235 13.6634 8.1468C14.0159 8.3643 14.1031 8.8342 13.8702 9.17665C13.6371 9.51915 13.1803 9.59295 12.8253 9.3795C12.68 9.2922 12.5285 9.2134 12.3596 9.14775C11.9161 8.97535 11.456 8.9038 10.9821 8.89265C10.5502 8.8959 10.1317 9.0169 9.84125 9.19255C9.52915 9.38125 9.50005 9.53645 9.50005 9.57115C9.50005 9.7554 9.62315 9.84065 9.77975 9.9164C10.2129 10.1259 10.7196 10.1867 11.188 10.2716C11.7096 10.366 12.3581 10.4834 12.8735 10.7327C13.5456 11.0578 14.0001 11.6648 14.0001 12.4283C14.0001 13.2099 13.4667 13.769 12.935 14.0905C12.5865 14.3012 12.1777 14.4524 11.7501 14.5348V15.0003C11.7501 15.4145 11.4143 15.7503 11.0001 15.7503C10.5859 15.7503 10.2501 15.4145 10.2501 15.0003V14.5527C9.79195 14.4864 9.40145 14.3681 9.09705 14.2498C9.09705 14.2498 8.9291 14.2185 8.3366 13.8527C7.98415 13.6351 7.89695 13.1653 8.1299 12.8228C8.3629 12.4803 8.8198 12.4065 9.1748 12.6199C9.3201 12.7072 9.4716 12.786 9.6405 12.8517C9.9686 12.9792 10.4381 13.1068 11.0001 13.1068C11.4384 13.1068 11.8644 12.985 12.1589 12.8069C12.4709 12.6182 12.5001 12.463 12.5001 12.4283C12.5001 12.2441 12.377 12.1588 12.2203 12.083C11.7872 11.8735 11.2805 11.8127 10.8121 11.7279C10.2906 11.6334 9.642 11.516 9.12655 11.2667C8.4545 10.9416 8.00005 10.3346 8.00005 9.57115C8.00005 8.7895 8.5334 8.23045 9.0651 7.90895C9.4136 7.6982 9.8224 7.547 10.2501 7.46465V7C10.2501 6.5858 10.5859 6.25 11.0001 6.25Z"
                  fill="black"
                />
              </g>
              <defs>
                <clipPath id="clip0_1945_28004">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
            Never any hidden costs
          </Header>
          <Text>
            Unlike other Cycle to Work schemes you will{" "}
            <b>never face a surcharge</b> when using a <b>go</b>geta voucher.
          </Text>
          <Text>
            As the bike is technically hired for the duration of the salary
            sacrifice, there needs to be ownership fee at the end to satisfy
            HMRC.
            <br />
            <b>
              Other schemes charge 7% of the bike price! Rest assured gogeta
              charges a mere £1
            </b>
          </Text>
        </GreenBlock>
      </BottomWrapper>
    </Wrapper>
  );
};

const Wrapper = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  borderRadius: "25px",
  overflow: "hidden",
  background: "#FFFFFF",
  width: "100%",
}));

const InputsWrapper = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "16px",
}));

const BlueWrapper = styled(Box)(() => ({
  borderRadius: "8px",
  background: "#F3F9F9",
  display: "flex",
  flexDirection: "column",
  gap: "18px",
  padding: "16px 0",
  "&  >  p": {
    color: "#0A244B",
    fontSize: "15px",
    fontFamily: "'Lexend', sans-serif;",
    fontWeight: 600,
    lineHeight: "20px",
    padding: "0 16px",
  },
}));

const Top = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column-reverse",
  gap: "16px",
  padding: "16px",
  fontSize: "32px",
  fontFamily: "'Lexend', sans-serif;",
  fontWeight: 700,
  lineHeight: " 125%",
  letterSpacing: "-0.32px",
  color: "",
  boxSizing: "border-box",
  background: "#BFD2B6",
  borderRadius: 0,
  "& a": { height: "60px" },
  "& img": { height: "60px", width: "fit-content" },
  "@media (min-width: 768px)": {
    padding: "24px",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  "@media (min-width: 1220px)": {
    padding: "50px",
  },
}));

const Header = styled(Typography)(() => ({
  fontSize: "14px",
  fontFamily: "'Lexend', sans-serif;",
  fontWeight: 900,
  color: "#0A244B",
  display: "flex",
  flexDirection: "row",
  gap: "12px",
  alignItems: "center",
  marginBottom: "10px",
}));

const Text = styled(Typography)(() => ({
  fontSize: "14px",
  fontFamily: "'Lexend', sans-serif;",
  fontWeight: 400,
  color: "#0A244B",
}));

const SmallItems = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "20px",
  width: "100%",
  "@media (min-width: 768px)": {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gap: "20px",
  },
}));

const Bottom = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "32px",
  width: "100%",
  "@media (min-width: 1220px)": {
    gap: "64px",
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
  },
}));

const BottomWrapper = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "32px",
  width: "100%",
  padding: "16px",
  boxSizing: "border-box",
  borderRadius: 0,
  background: "#FFFFFF",
  "@media (min-width: 1220px)": {
    padding: "50px",
  },
}));

const Left = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
}));

const Right = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "20px",
}));

const GreenBlock = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "12px",
  boxSizing: "border-box",
  padding: "20px 24px",
  background: "linear-gradient(191deg, #C5E2DF 0%, #C2D9C4 90.47%)",
  borderRadius: "20px",
}));
