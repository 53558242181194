import { Box, Typography, styled } from "@mui/material";
import React from "react";

interface ICalcItem {
  title: string;
  icon?: any;
  value: string;
  background?: string;
  titleColor?: string;
  shortTextColor?: string;
  textColor?: string;
  isLarge?: boolean;
}

export const CalcItem = ({
  title,
  icon,
  value,
  background,
  titleColor,
  textColor,
  shortTextColor,
  isLarge = false,
}: ICalcItem) => {
  return (
    <Wrapper
      isLarge={isLarge}
      style={{ background: background ? background : "#C5E2E0" }}
    >
      <Left>
        <Header
          isLarge={isLarge}
          style={{ color: titleColor ? titleColor : "#155C7E" }}
        >
          {title}{" "}
          <ImageWrapper isLarge={isLarge}>
            <img loading="lazy" alt="icon" src={icon} width={34} height={34} />
          </ImageWrapper>
        </Header>
        <Text
          dangerouslySetInnerHTML={{ __html: value }}
          sx={{
            color: textColor ? textColor : "#155C7E",
            "& span": {
              color: shortTextColor && shortTextColor,
            },
          }}
          isLarge={isLarge}
        />
      </Left>
    </Wrapper>
  );
};

const Wrapper = styled(Box)(({ isLarge }: { isLarge: boolean }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  padding: isLarge ? "24px 30px" : "24px",
  boxSizing: "border-box",
  borderRadius: "20px",
  height: "100%",
  "@media (min-width: 768px)": {
    maxHeight: "149px",
  },
}));

const Left = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "12px",
  justifyContent: "space-between",
  width: "100%",
}));

const Header = styled(Typography)(({ isLarge }: { isLarge: boolean }) => ({
  fontSize: isLarge ? "20px" : "14px",
  fontWeight: 600,
  lineHeight: "20px",
  letterSpacing: "0.4px",
  display: "flex",
  flexDirection: "row",
  gap: "24px",
  justifyContent: "space-between",
  fontFamily: "'Lexend', sans-serif;",
}));

const Text = styled(Typography)(({ isLarge }: { isLarge: boolean }) => ({
  fontSize: isLarge ? "36px" : "32px",
  fontFamily: "'Lexend', sans-serif;",
  fontWeight: 500,
  lineHeight: "26px",
  "& span": {
    fontSize: isLarge ? "20px" : "18px",
    fontWeight: 500,
    lineHeight: "26px",
    whiteSpace: "nowrap",
    height: "23px",
    display: "block",
    fontFamily: "'Lexend', sans-serif;",
  },
}));

const ImageWrapper = styled(Box)(({ isLarge }: { isLarge: boolean }) => ({
  display: "flex",
  alignItems: isLarge ? "center" : "start",
}));
