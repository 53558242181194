import React from 'react';
import { InviteCalc } from './calculator/InviteCalc';

function App() {
  return (
    <InviteCalc/>
  );
}

export default App;
