import { TextField, Tooltip } from "@mui/material";
import React from "react";
import { Box, styled, Typography } from "@mui/material";
import { tooltipClasses, TooltipProps } from "@mui/material/Tooltip";

interface IFormTextField {
  name: string;
  updateField: (fieldName: string, value: string) => void;
  labelText: string;
  data: any;
  tooltipText: string;
  readOnly?: boolean;
  type?: string;
  errorText?: string;
  isError?: boolean;
}

export const CalcTextField = ({
  name,
  updateField,
  labelText,
  data,
  tooltipText,
  readOnly = false,
  type = "number",
  errorText = "error",
  isError = false,
}: IFormTextField) => {
  return (
    <Wrapper
      sx={{
        "& input": { background: readOnly ? "#F3F9F9" : "#FFFFFF" },
        marginBottom: isError ? "30px" : 0,
      }}
    >
      <Label>
        {tooltipText && (
          <CustomTooltip
            title={<div dangerouslySetInnerHTML={{ __html: tooltipText }} />}
            arrow
            placement={"right"}
            enterTouchDelay={0}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              style={{ minWidth: "24px", minHeight: "24px" }}
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 23.25C5.7868 23.25 0.75 18.2132 0.75 12C0.75 5.7868 5.7868 0.75 12 0.75C18.2132 0.75 23.25 5.7868 23.25 12C23.25 18.2132 18.2132 23.25 12 23.25ZM10 6.25C10 5.14545 10.8954 4.25 12 4.25C13.1046 4.25 14 5.14545 14 6.25C14 7.35455 13.1046 8.25 12 8.25C10.8954 8.25 10 7.35455 10 6.25ZM13.4403 10.557C13.405 9.98805 13.041 9.5458 12.4719 9.51275C12.3347 9.5048 12.1782 9.5 12 9.5H10.25C9.95725 9.5 9.71275 9.50725 9.50935 9.5185C8.91605 9.55145 8.51975 9.989 8.5049 10.583C8.5018 10.7067 8.5 10.8452 8.5 11C8.5 11.1548 8.5018 11.2933 8.5049 11.417C8.51975 12.011 8.91605 12.4485 9.50935 12.4815C9.71275 12.4927 9.95725 12.5 10.25 12.5C10.3383 12.5 10.4221 12.4994 10.5019 12.4981C10.5007 12.6569 10.5 12.8242 10.5 13V16.0142C9.86505 16.0273 9.39135 16.048 9.0439 16.0692C8.49695 16.1027 8.05795 16.4392 8.01755 16.9858C8.0067 17.1328 8 17.3032 8 17.5C8 17.6968 8.0067 17.8672 8.01755 18.0142C8.05795 18.5607 8.49695 18.8974 9.0439 18.9308C9.62365 18.9662 10.5548 19 12 19C13.4452 19 14.3764 18.9662 14.9561 18.9308C15.5031 18.8974 15.942 18.5607 15.9824 18.0142C15.9933 17.8672 16 17.6968 16 17.5C16 17.3032 15.9933 17.1328 15.9824 16.9858C15.942 16.4392 15.5031 16.1027 14.9561 16.0692C14.6087 16.048 14.1349 16.0273 13.5 16.0142V13C13.5 11.8485 13.4721 11.07 13.4403 10.557Z"
                fill={"#F25D1D"}
              />
            </svg>
          </CustomTooltip>
        )}
        <p dangerouslySetInnerHTML={{ __html: labelText }} />
      </Label>
      <Box
        sx={{
          position: "relative",
        }}
      >
        <CustomTextField
          name={name}
          value={
            type === "number"
              ? data[name]
              : Number(data[name]).toLocaleString("en-US")
          }
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            updateField(name, e.target.value)
          }
          type={type}
          InputProps={{
            readOnly: readOnly,
          }}
          isDark={name === "voucherValue"}
          error={isError}
          helperText={isError ? errorText : ""}
        />
        <IconWrapper isDark={name === "voucherValue"} className="currency">
          £
        </IconWrapper>
      </Box>
    </Wrapper>
  );
};

const Wrapper = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "12px",
  padding: "0 16px",
  width: "100%",
  boxSizing: "border-box",
  "@media (min-width: 768px)": {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: "32px",
    height: 44,
    maxHeight: 44,
  },
}));

const CustomTextField = styled(TextField)(
  ({ isDark }: { isDark: boolean }) => ({
    fontFamily: "'Lexend', sans-serif;",
    height: "44px",
    width: "100%",
    "@media (max-width: 767px)": {
      width: "100%",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: `1px solid ${isDark ? "#155C7E" : "#C5E2E0"}`,
        borderRadius: 15,
      },
      "&:hover fieldset": {
        border: `1px solid ${isDark ? "#155C7E" : "#a4c9c6"}`,
      },
      "&.Mui-focused fieldset": {
        border: `1px solid ${isDark ? "#155C7E" : "#C5E2E0"}`,
      },
    },
    "& .MuiInputLabel-root": {
      color: "#0A244B",
      opacity: "0.5",
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: "#a4c9c6",
      opacity: 1,
    },
    "& input": {
      background: "white",
      borderRadius: "15px",
      fontFamily: "'Lexend', sans-serif;",
      fontSize: "14px",
      fontWeight: 400,
      boxSizing: "border-box",
      height: "44px",
      minWidth: "100%",
      padding: "16px 14px 16px 52px",
      "@media (max-width: 767px)": {
        width: "100%",
      },
      "@media (min-width: 768px)": {
        fontSize: 16,
      },

      // '@media (min-width: 1220px)': {
      //   width: '100%',
      //   minWidth: '275px',
      // },
    },
  })
);

const IconWrapper = styled(Box)(({ isDark }: { isDark: boolean }) => ({
  width: "44px",
  height: "44px",
  position: "absolute",
  content: '""',
  background: isDark ? "#155C7E" : "#C5E2E0",
  borderRight: `1px solid ${isDark ? "#155C7E" : "#C5E2E0"}`,
  color: isDark ? "#ffffff" : "#0A244B",
  left: 0,
  top: 0,
  borderRadius: "15px 0 0 15px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontFamily: "'Lexend', sans-serif;",
  fontSize: "14px",
  fontWeight: 600,
  "@media (min-width: 768px)": {
    fontSize: 16,
  },
}));

const Label = styled(Typography)(() => ({
  fontFamily: "'Lexend', sans-serif;",
  fontWeight: 700,
  fontSize: "14px",
  color: "#0A244B",
  display: "flex",
  alignItems: "center",
  flexDirection: "row",
  gap: "12px",
  "& > p": { margin: 0 },
  "& span": {
    fontSize: "12px",
    fontWeight: 400,
  },
}));

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#0e0036",
    color: "white",
    fontFamily: "'Lexend', sans-serif;",
    fontSize: theme.typography.pxToRem(16),
    padding: "30px",
    display: "flex",
  },
}));
